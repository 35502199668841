import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { remCalc } from '../styles/Mixins';
import { mq } from '../styles/breakpoints';
import LinkedIn from '../assets/images/linkedin.inline.svg';
import Facebook from '../assets/images/facebook.inline.svg';
import Instagram from '../assets/images/instagram.inline.svg';
import Twitter from '../assets/images/twitter.inline.svg';
import InlineSvg from './InlineSVG';

const NavStyles = styled.nav`
  background-color: var(--white);
  border-bottom: 1.5px solid var(--blue-dark);
  border-top: 1.5px solid var(--blue-dark);
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.07);
  left: 0;
  position: fixed;
  top: 4.875rem;
  transition: transform 600ms ease-out;
  transform: ${({ open }) => (open ? 'translateY(0)' : 'translateY(-200%)')};
  width: 100%;
  will-change: transform;
  z-index: 3;

  .navList {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }

  .menuItem {
    border-top: 1.5px solid var(--blue-dark);
    display: flex;
    font-size: 0.875rem;
    justify-content: center;
    width: 100%;

    a {
      color: var(--blue-dark);
      display: flex;
      padding: ${remCalc(10)} ${remCalc(15)};
      text-transform: uppercase;

      span {
        &:after {
          background-color: var(--blue-dark);
          content: '';
          display: block;
          height: 1px;
          transform: scaleX(0);
          transition: transform 200ms ease-in;
          transform-origin: left top;
          width: 100%;
        }
      }
      &:hover span:after {
        transform: scaleX(1);
      }

      &.active,
      &[aria-current='page'] {
        span {
          position: relative;

          &:after {
            background-color: var(--blue-dark);
            content: '';
            display: block;
            height: 1px;
            transform: scaleX(1);
            width: 100%;
          }
        }
      }
    }
  }

  ${mq[2]} {
    background-color: transparent;
    border: none;
    box-shadow: none;
    height: auto;
    position: relative;
    top: 0;
    transform: none;
    transition: none;
    width: auto;

    .navList {
      align-items: flex-start;
      flex-direction: row;
      height: auto;
      justify-content: flex-start;
    }
    .menuItem {
      border: none;

      &:first-child {
        margin-left: ${remCalc(-15)};
      }
    }

    .iconsMedias {
      display: none;
    }
  }

  .second-nav {
    display: flex;
    justify-content: space-around;
    width: 100%;

    ${mq[2]} {
      display: none;
    }

    .text {
      margin-top: 0.8125rem;
      display: block !important;
    }
  }
`;

export default function Nav({ navItems, socialMedias, open, setOpen }) {
  const items = navItems.mainNavigation;

  return (
    <NavStyles open={open}>
      <ul className="navList">
        <ul className="second-nav">
          {navItems.secondNavigation.map((item, index) => (
            <li key={`tab-${index}`}>
              <Link
                to={`/${item.linkPage.slug.current}/`}
                activeClassName="active"
              >
                <div className="icon-bg">
                  <InlineSvg src={item.icon} />
                </div>
                {item.title && <span className="text">{item.title}</span>}
              </Link>
            </li>
          ))}
        </ul>
        {items.map((item, index) => (
          <li className="menuItem" key={`item-${index}`}>
            <Link
              to={`/${item.slug.current}/`}
              activeClassName="active"
              partiallyActive
              onClick={() => {
                setOpen(!open);
              }}
            >
              {item.titleMenu ? (
                <span className="text">{item.titleMenu}</span>
              ) : (
                <span className="text">{item.title}</span>
              )}
            </Link>
          </li>
        ))}
        <li className="menuItem iconsMedias">
          {socialMedias.linkedin && (
            <a to={socialMedias.linkedin}>
              <LinkedIn />
            </a>
          )}

          {socialMedias.facebook && (
            <a to={socialMedias.facebook}>
              <Facebook />
            </a>
          )}

          {socialMedias.instagram && (
            <a to={socialMedias.instagram}>
              <Instagram />
            </a>
          )}

          {socialMedias.twitter && (
            <a to={socialMedias.twitter}>
              <Twitter />
            </a>
          )}
        </li>
      </ul>
    </NavStyles>
  );
}
