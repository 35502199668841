import { createGlobalStyle } from 'styled-components';
import { remCalc } from './Mixins';
import { mq } from './breakpoints';

const GlobalStyles = createGlobalStyle`
  :root {
    --grey: #F2F2F2;
    --grey-dark: #2e2e2e;
    --white: #ffffff;
    --blue: #64A6AC;
    --blue-dark: #33576C;
    --yellow: #EBC732;
    --red: #B92753;
    --rose: #FF928E;
    --font: 'Museo Sans', sans-serif;
    --font-titles: 'Museo', serif;
    --gap: 2.5rem; /* 40px */
    --radius: 5px;
    --margin: 1.5625rem;
    --padding: 3.125rem;
  }

  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
      background: var(--white);
      color: var(--blue-dark);
      font-family: var(--font);
      font-size: 16px;
      line-height: 1.4;
      -webkit-font-smoothing: antialiased;
      overflow-x: hidden;
  }

  ul,
  ol {
      list-style: none;
      margin: 0;
      padding: 0;
  }

  img {
    max-width: 100%;
  }

  a {
    color: var(--blue-dark);
    text-decoration: none;
    transition: color 200ms linear;

    &:hover {
      color: var(--blue);
    }
  }

  /* Layout */
  html, 
  body,
  #___gatsby,
  #gatsby-focus-wrapper,
  .app {
    min-height: 100vh;
    overflow-x: hidden;
  }

  .container {
    margin: 0 auto;
    max-width: 52.125rem;
    width: 90%;
  }

  .content {
    padding-top: 9.625rem;

    .home & {
      padding-top: 5rem;
    }

    ${mq[2]} {
      padding-top: 6.25rem;

      .home & {
        padding-top: 6.25rem;
      }
    }
  }

  /* Second Nav */
  .second-nav {
    li {
      align-items: center;
      display: flex;
      flex-direction: column;
      max-width: 6.25rem;
      margin: 0.9375rem auto;
    }

    .icon-bg {
      background: var(--blue-dark);
      border: 1px solid var(--white);
      border-radius: 100%;
      height: 2.8125rem;
      margin: auto;
      padding: 0.5563rem 0.7625rem 0.8375rem 0.7438rem;
      width: 2.8125rem;
    }

    a {
      font-size: 0.75rem;
      text-align: center;
      text-transform: uppercase;

      &:hover,
      &:active,
      &:focus {
        .icon-bg {
          background: var(--rose);
        }

        .text {
          color: var(--rose);
        }
      }
    }
  }

  /* Sections */
  .section {
    position: relative;
  }

  /* Custom styles */
  .mobile {
    display: block;

    ${mq[1]} {
      display: none;
    }
  }
  .desktop {
    display: none;

    ${mq[1]} {
      display: block;
    }
  }

  .block {
    .heading-2 {
      font-size: 1.5rem;
      font-weight: 300;
    }
    .heading-3 {
      font-size: 1.25rem;
      font-weight: 300;
    }
    .heading-4 {
      font-size: 1rem;
      font-weight: 300;
      text-transform: uppercase;
    }
    a {
      text-decoration: underline;
    }
    > *:last-child {
      margin-bottom: 0;
    }
  }

  .button {
    align-items: center;
    background: transparent;
    border: 1.5px solid var(--blue-dark);
    color: var(--blue-dark);
    cursor: pointer;
    display: inline-flex;
    font-family: var(--font);
    font-size: 0.9375rem;
    font-weight: 500;
    justify-content: center;
    min-height: 2.375rem;
    min-width: 3.125rem;
    padding: 7px 1.25rem 5px;
    text-align: center;
    text-transform: uppercase;
    transition: none;
    width: 12rem;

    ${mq[3]} {
        width: 15rem;
    }

    &:hover {
        background-color: var(--rose);
        border: 1.5px solid var(--white);
        color: var(--white);
    }
    
    &__second {
        border: 1.5px solid var(--white);
        color: var(--white);
        
        ${mq[3]} {
            max-width: none;
            padding: ${remCalc(12)} ${remCalc(58)};
        }
    }

    &__third {
        background-color: var(--white);
        border: 1.5px solid var(--blue-dark);
        color: var(--blue-dark);

        &:hover {
            background-color: var(--blue-dark);
            border: 1.5px solid var(--white);
            color: var(--white);
        }
    }
  }

  .invisible {
    display: none;
  }
  .visible {
    display: block;
  }

  .video-wrapper {
    margin: 2rem 0;
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
  }
  .video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .title-container {
    padding: var(--margin) 0;

    .section-title {
      text-align: center;
    }
  }

  /* Arrow to go back */
  .back {
    align-items: center;
    display: inline-flex;
    margin-bottom: 1.4375rem;

    margin-top: 0.4em;    

    &:hover .back__arrow path {
      stroke: var(--blue);
    }

    &__arrow {
      height: 0.8337rem;
      margin: 0;
    }

    &__label {
      margin-left: 0.7625rem;
      margin-right: 0.7625rem;
      font-weight: bold;
    }
  }

  /* Arrow to go next */
  .next {
    align-items: center;
    display: flex;
    margin-left: auto;
    margin-top: 1.4375rem;
    width: fit-content;

    &:hover .next__arrow path {
      stroke: var(--blue);
    }

    &__arrow {
      height: 0.8337rem;
      margin: 0;
      transform: rotate(180deg);
    }

    &__label {
      margin-left: 0.7625rem;
      margin-right: 0.7625rem;
    }
  }

  /* accessibility */
  .sr-only { 
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    width: 1px;
    height: 1px;
    overflow: hidden;
    padding: 0;
    border: 0;
    white-space: nowrap;
  }

  .sr-only.focusable:active,
  .sr-only.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }

  /* Base for label styling */
  [type="checkbox"]:not(:checked),
  [type="checkbox"]:checked {
    position: absolute;
    left: 0;
    opacity: 0.01;
  }
  [type="checkbox"]:not(:checked) + label,
  [type="checkbox"]:checked + label {
    position: relative;
    padding-left: 2.3em;
    /* font-size: 1.05em; */
    line-height: 1.7;
    cursor: pointer;
  }

  /* checkbox aspect */
  [type="checkbox"]:not(:checked) + label:before,
  [type="checkbox"]:checked + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 23px;
    height: 23px;
    border: 1px solid #aaa;
    background: #FFF;
    border-radius: .2em;
    box-shadow: inset 0 1px 3px rgba(0,0,0, .1), 0 0 0 rgba(145, 109, 91, 0.2);
    transition: all .275s;
  }

  /* checked mark aspect */
  [type="checkbox"]:not(:checked) + label:after,
  [type="checkbox"]:checked + label:after {
    color: var(--blue-dark);
    content: '✓';
    position: absolute;
    top: 0.75rem;
    left: 0.3125rem;
    font-size: 1.375em;
    line-height: 0;
    transition: all .2s;
  }

  /* checked mark aspect changes */
  [type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0) rotate(45deg);
  }

  [type="checkbox"]:checked + label:after {
    opacity: 1;
    transform: scale(1) rotate(0);
  }

  /* Accessibility */
  [type="checkbox"]:checked:focus + label:before,
  [type="checkbox"]:not(:checked):focus + label:before {
    box-shadow: inset 0 1px 3px rgba(0,0,0, .1), 0 0 0 6px rgba(145, 109, 91, 0.2);
  }

  /* Custom CSS Select */
  .select-css {
    appearance: none;
    background-color: #fff;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23916D5B%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat;
    background-position: right .7em top 50%;
    background-size: .65em auto;
    border-radius: var(--radius);
    border: 1px solid var(--blue);
    box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
    color: var(--blue);
    display: block;
    font-size: 1rem;
    font-family: var(--font);
    font-weight: 500;
    line-height: 1.3;
    margin: 0;
    padding: .6em 1.4em .5em .8em;
    width: 100%;
    max-width: 100%; /* useful when width is set to anything other than 100% */
  }
  .select-css::-ms-expand {
    display: none;
  }
  /* Hover style */
  .select-css:hover {
    border-color: var(----grey-dark);
  }
  /* Focus style */
  .select-css:focus {
    border-color: #aaa;
    /* It'd be nice to use -webkit-focus-ring-color here but it doesn't work on box-shadow */
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: var(--grey-dark);
    outline: none;
  }

  /* Set options to normal weight */
  .select-css option {
    font-weight:normal;
  }

  .iconsMedias {
      display: flex;
      justify-content: center;

    a {
      padding: ${remCalc(12)} ${remCalc(15)};
    }

    svg {
      height: 0.9794rem;
    }
  }

  .image-header {
    margin-bottom: 2em;
  }
`;

export default GlobalStyles;
