/* eslint-disable no-unused-expressions */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import 'normalize.css';
import styled from 'styled-components';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useLocation } from '@reach/router'; // this helps tracking the location
import { Helmet } from 'react-helmet';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';
import Header from './Header';
import SecondNav from './SecondNav';
import Footer from './Footer';
import CookieConsent from './CookieConsent';

if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger);
}

const LayoutStyles = styled.div`
  min-height: 100%;

  .content {
    min-height: calc(100vh - 23.8701rem);
  }
`;

const Layout = ({ pageContext, children }) => {
  const { navItems, socialMedias, footerItems, logo } = useStaticQuery(
    graphql`
      query {
        logo: sanitySingletonSite(_id: { eq: "singletonSite" }) {
          logo {
            image {
              asset {
                url
              }
            }
          }
        }
        navItems: sanitySingletonSite(_id: { eq: "singletonSite" }) {
          mainNavigation {
            ... on SanityPage {
              id
              _type
              title
              titleMenu
              slug {
                current
              }
            }
            ... on SanitySingletonFAQ {
              id
              _type
              title
              titleMenu
              slug {
                current
              }
            }
          }
          secondNavigation {
            title
            icon
            linkPage {
              slug {
                current
              }
            }
          }
        }
        socialMedias: sanitySingletonSite(_id: { eq: "singletonSite" }) {
          facebook
          linkedin
          instagram
          twitter
        }
        footerItems: sanitySingletonSite(_id: { eq: "singletonSite" }) {
          address
          contactName
          mail
          tel
          subtitle
          title
          footerNavigation {
            ... on SanityPage {
              id
              _type
              title
              titleMenu
              slug {
                current
              }
            }
            ... on SanitySingletonFAQ {
              id
              _type
              title
              titleMenu
              slug {
                current
              }
            }
          }
          description
        }
      }
    `
  );
  const navRef = useRef();
  const tlToggleNav = useRef();
  const nav = navRef.current;

  const location = useLocation();
  const slug = location.pathname.replace(/[\/\\]/g, '');

  const secondNav = navItems?.secondNavigation;

  useEffect(() => {
    if (pageContext?.slug === 'home') {
      const target = document.querySelector('.intro-home').nextSibling;
      const contentH = document.querySelector('.content').offsetHeight;

      tlToggleNav.current = gsap.timeline({
        scrollTrigger: {
          trigger: target,
          // markers: true,
          start: 'bottom top',
          end: `${contentH} top`,
          toggleActions: 'play reverse play reverse',
        },
      });

      tlToggleNav.current.to(navRef.current, {
        autoAlpha: 1,
        pointerEvents: 'auto',
      });
    }

    return () => {
      nav && nav.setAttribute('style', '');
      tlToggleNav.current && tlToggleNav.current.kill();
    };
  }, [nav, pageContext?.slug]);

  return (
    <div className={`app ${pageContext?.slug || slug}`}>
      <GlobalStyles />
      <Typography />
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "LocalBusiness",
              "name": "Early Birds",
              "image": "",
              "@id": "",
              "url": "https://www.earlybirds.paris/",
              "telephone": "06 24 62 55 43",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "24 rue d’Aumale",
                "addressLocality": "Paris",
                "postalCode": "75009",
                "addressCountry": "FR"
              },
              "openingHoursSpecification": [{
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday"
                ],
                "opens": "09:00",
                "closes": "19:00"
              }]
            },
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Early Birds",
              "alternateName": "Early Birds",
              "url": "https://www.earlybirds.paris/",
              "logo": "${logo?.logo?.image?.asset?.url}"
            }
        `}
        </script>
      </Helmet>
      <LayoutStyles>
        <Header
          navItems={navItems}
          socialMedias={socialMedias}
          pageContext={pageContext}
        />
        <SecondNav secondNav={secondNav} className="second-nav" ref={navRef} />
        <div className="content">{children}</div>
        <CookieConsent />
        <Footer footerItems={footerItems} socialMedias={socialMedias} />
      </LayoutStyles>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
