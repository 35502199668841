import { Link } from 'gatsby';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import gsap from 'gsap';
import styled from 'styled-components';
import Logo from '../assets/images/logo-earlybirds.inline.svg';
import { pxtoem } from '../styles/Mixins';
import Nav from './Nav';
import { mq } from '../styles/breakpoints';
import Burger from './Burger';
import Contact from '../assets/images/contact.inline.svg';
import InlineSvg from './InlineSVG';

const HeaderStyles = styled.header`
  /* box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15); */
  position: fixed;
  transition: transform 200ms linear;
  width: 100%;
  will-change: transform;
  z-index: 6;

  & > .second-nav {
    background: var(--white);
    border-top: 1.5px solid var(--blue-dark);
    border-bottom: 1.5px solid var(--blue-dark);
    display: flex;
    /* transform: translateY(-100%);
    transition: transform 200ms ease-out; */
    padding-left: calc(5% + 0.5rem);
    padding-right: calc(5% + 0.5rem);
    position: fixed;
    top: 0;
    z-index: 4;
    transform: translateY(-100%);
    width: 100%;

    .text {
      display: none;
      margin-top: 0.8125rem;
      /* transform: scaleY(0);
      transition: transform 200ms ease-out; */
    }

    ${mq[2]} {
      display: none;
      position: relative;
    }
  }

  .second-nav-pages {
    transform: translateY(0);
  }

  .container {
    background-color: white;
    align-items: center;
    display: flex;
    justify-content: space-between;
    max-width: none;
    padding: 12px 5%;
    position: relative;
    width: 100%;
    z-index: 5;
  }
  .logo {
    align-items: center;
    display: flex;

    svg {
      display: block;
      width: 3.4375rem;
      z-index: 2;
    }

    p {
      color: var(--blue-dark);
      display: none;
      font-family: var(--font-titles);
      font-size: 1.375rem;
      margin: 0 0 0 0.75rem;
    }

    &:hover {
      .rose {
        fill: var(--blue-dark);
      }

      .blue-dark {
        fill: var(--rose);
      }
    }
  }
  .contact {
    /* height: 2.1875rem; */
    width: 2.0925rem;
    z-index: 5;
  }

  ${mq[2]} {
    --padding: calc((100% - 73.75rem) / 2);
    align-items: center;
    background: var(--white);
    display: flex;
    justify-content: space-between;
    padding-left: max(var(--padding), 2rem);
    padding-right: max(var(--padding), 2rem);
    padding-top: ${pxtoem(22)};
    padding-bottom: ${pxtoem(22)};
    width: 100%;

    .container {
      justify-content: space-between;
      padding: 0;
      position: static;
    }

    .logo {
      svg {
        height: ${pxtoem(55)};
        width: auto;
      }

      p {
        display: block;
      }
    }

    .contact {
      display: none;
    }
  }
`;

const Header = ({ navItems, socialMedias, pageContext }) => {
  const [open, setOpen] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [boxShadow, setBoxShadow] = useState(false);
  const [showText, setShowText] = useState('none');
  const [isScrolled, setIsScrolled] = useState(false);
  const [headHeightTranslate, setHeadHeightTranslate] =
    useState(`translateY('-100%')`);
  const headerRef = useRef(null);

  let windowWidth = 0;

  if (typeof window !== 'undefined') {
    windowWidth = window.innerWidth;
  }

  const [width, setWidth] = React.useState(windowWidth);
  const breakpoint = 1280;

  useEffect(() => {
    const secondNavHeader = headerRef.current.querySelector('.second-header');
    const secondNavIntro = document.querySelector('.intro-home .second-nav');

    if (pageContext.slug === 'home') {
      gsap.to(secondNavHeader, {
        scrollTrigger: {
          end: 'bottom top',
          endTrigger: 'html',
          start: 'bottom top',
          trigger: secondNavIntro,
          toggleActions: 'play pause resume reset',
          onToggle: ({ isActive }) => setIsScrolled(isActive),
        },
        y: 0,
      });
    } else {
      setIsScrolled(true);
    }

    // return setIsScrolled(false);
  }, [pageContext.slug, isScrolled]);

  // useEffect(() => {
  //   // Show span .text under icons when opening mobile menu
  //   if (open && isScrolled) {
  //     setShowText('block');
  //   } else {
  //     setShowText('none');
  //   }

  //   // return setShowText('none');
  // }, [open, isScrolled, showText]);

  useEffect(() => {
    if (open) {
      setHeadHeightTranslate('translateY(-100%)');
    }
  }, [open]);

  useEffect(() => {
    const handleResizeWindow = () => {
      setWidth(window.innerWidth);
      // setHeadHeightTranslate(`translateY(${headerRef.current.clientHeight}px)`);
      // console.log(headHeightTranslate);
    };
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener('resize', handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, []);

  const handleScroll = useCallback(() => {
    // Set Burger menu visible on Mobile
    if (windowWidth < breakpoint) {
      // find current scroll position
      const currentScrollPos = window.pageYOffset;

      const isScrollingUp = prevScrollPos > currentScrollPos;
      const hasScrolledEnough = currentScrollPos > 200;

      // set state based on location info (explained in more detail below)
      if (!open) {
        setIsHeaderVisible(isScrollingUp || !hasScrolledEnough);

        if (isScrollingUp && hasScrolledEnough) {
          setHeadHeightTranslate(
            `translateY(${headerRef.current.clientHeight}px)`
          );
        } else {
          setHeadHeightTranslate('translateY(-100%)');
        }
      } else {
        setHeadHeightTranslate('translateY(-100%)');
      }

      // set state to new scroll position
      setPrevScrollPos(currentScrollPos);
    }
  }, [open, prevScrollPos, windowWidth]);

  const displayBoxShadow = useCallback(() => {
    const currentScrollPos = window.pageYOffset;
    setBoxShadow(
      (prevScrollPos < currentScrollPos &&
        prevScrollPos - currentScrollPos < 73) ||
        currentScrollPos > 10
    );
  }, [prevScrollPos]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos, isHeaderVisible, handleScroll]);

  useEffect(() => {
    window.addEventListener('scroll', displayBoxShadow);

    return () => window.removeEventListener('scroll', displayBoxShadow);
  }, [prevScrollPos, isHeaderVisible, displayBoxShadow]);

  return (
    <HeaderStyles
      id="header"
      ref={headerRef}
      style={{
        transform: isHeaderVisible ? 'translateY(0)' : 'translateY(-100%)',
        boxShadow: boxShadow ? '0px 3px 8px rgba(0, 0, 0, 0.07)' : 'none',
      }}
    >
      <div className="container">
        <Burger open={open} setOpen={setOpen} />
        <Link to="/" className="logo">
          <Logo />
          <p>Early Birds</p>
        </Link>
        <Link to="/nous-contacter" className="contact">
          <Contact />
        </Link>
      </div>
      <Nav
        navItems={navItems}
        socialMedias={socialMedias}
        open={open}
        setOpen={setOpen}
      />
      <ul
        className={`second-nav second-header ${
          pageContext.slug !== 'home' ? 'second-nav-pages' : ''
        }`}
        style={{
          transform: headHeightTranslate,
        }}
      >
        {navItems.secondNavigation.map((item, index) => (
          <li key={`tab-${index}`}>
            <Link
              to={`/${item.linkPage.slug.current}/`}
              activeClassName="active"
            >
              <div className="icon-bg">
                <InlineSvg src={item.icon} />
              </div>
              {item.title && (
                <span className="text" style={{ display: showText }}>
                  {item.title}
                </span>
              )}
            </Link>
          </li>
        ))}
      </ul>
    </HeaderStyles>
  );
};

export default Header;
