import * as React from 'react';
import { useLocation } from '@reach/router';
import styled from 'styled-components';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import { Link } from 'gatsby';
import { mq } from '../styles/breakpoints';

function isBrowser() {
  return typeof window !== 'undefined';
}

function getValue(key, defaultValue) {
  return isBrowser() && window.localStorage.getItem(key)
    ? JSON.parse(window.localStorage.getItem(key))
    : defaultValue;
}

function setValue(key, value) {
  window.localStorage.setItem(key, JSON.stringify(value));
}

function useStickyState(defaultValue, key) {
  const [value, setter] = React.useState(() => {
    return getValue(key, defaultValue);
  });

  React.useEffect(() => {
    setValue(key, value);
  }, [key, value]);

  return [value, setter];
}

const ConsentStyles = styled.div`
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
  bottom: 5%;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  left: 5%;
  max-width: 90%;
  padding: 2rem;
  position: fixed;
  z-index: 9999;

  button {
    background-color: var(--blue);
    border: none;
    border-radius: 5px;
    color: var(--white);
    cursor: pointer;
    padding: 0.5rem 1rem;

    &.deny {
      background-color: var(--white);
      border: 1px solid var(--blue);
      color: var(--blue);
    }
    ${mq[1]} {
      &:first-child {
        margin-bottom: 1rem;
      }
    }
  }

  ${mq[0]} {
    max-width: 650px;
    min-height: 12.5rem;
  }
  ${mq[1]} {
    bottom: 10%;
    flex-direction: row;
    left: 10%;
    min-height: 12.5rem;
  }

  a {
    text-decoration: underline;
  }
  .text {
    p:first-child {
      margin-top: 0;
    }
    p:last-child {
      margin-bottom: 0;
    }

    ${mq[1]} {
      width: 60%;
    }
  }
  .actions {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    width: 100%;

    ${mq[1]} {
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      margin-top: 0;
      padding-left: 1rem;
      width: 40%;
    }
  }
`;

const CookieConsent = () => {
  const location = useLocation();
  if (isBrowser()) {
    initializeAndTrack(location);
  }

  const [bannerHidden, setBannerHidden] = useStickyState(
    false,
    'consentCookieHidden'
  );

  console.log(bannerHidden);

  const EnableAnalytics = () => {
    document.cookie = 'gatsby-gdpr-google-analytics=true';
    setBannerHidden(true);
  };

  const hideBanner = () => {
    setBannerHidden(true);
  };

  return (
    <>
      {!bannerHidden && (
        <ConsentStyles>
          <div className="text">
            <p>Nous utilisons les cookies pour analyser notre trafic.</p>
            <p>
              Voir notre{' '}
              <Link to="/mentions-legales">politique de confidentialité</Link>
            </p>
          </div>
          <div className="actions">
            <button type="button" onClick={hideBanner} className="deny">
              Refuser
            </button>
            <button type="button" onClick={EnableAnalytics}>
              Accepter
            </button>
          </div>
        </ConsentStyles>
      )}
    </>
  );
};

export default CookieConsent;
