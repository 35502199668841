import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { mq } from '../styles/breakpoints';
import Bird from '../assets/images/bird.inline.svg';
import LinkedIn from '../assets/images/linkedin.inline.svg';
import Facebook from '../assets/images/facebook.inline.svg';
import Instagram from '../assets/images/instagram.inline.svg';
import Twitter from '../assets/images/twitter.inline.svg';
import { numberWithSpaces } from '../lib/helpers';

const FooterStyles = styled.footer`
  background-color: var(--blue-dark);
  color: var(--white);
  font-size: 0.875rem;
  padding-bottom: 3.125rem;
  padding-top: 3.125rem;
  position: relative;
  text-align: center;

  .iconsMedias svg path {
    fill: white;
  }

  .container {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .title {
    font-family: var(--font-titles);
    font-size: 1.125rem;
    font-weight: 300;
    margin-bottom: 0;
    text-align: center;

    span {
      display: block;
      font-size: 1rem;
      font-style: italic;
      margin-top: 0.3563rem;
    }
  }

  .circle {
    background-color: var(--white);
    border-radius: 0.3125rem;
    height: 0.3125rem;
    margin: 1.0625rem;
    width: 0.3125rem;
  }

  .label {
    display: block;
    font-size: 0.75rem;
    margin-bottom: 0.875rem;
  }

  .contact {
    &--title {
      font-weight: bold;
    }

    &--text {
      margin-top: 0;
    }
  }

  a {
    color: white;
  }

  .bird {
    height: 2.2862rem;
  }

  nav {
    margin-top: 1rem;

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    li {
      margin-bottom: 0.75rem;
      text-transform: uppercase;

      ${mq[1]} {
        margin-bottom: 0;
      }
    }
    a {
      padding: 10px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const Footer = ({ footerItems, socialMedias }) => {
  const { facebook, linkedin, instagram, twitter } = socialMedias;

  const {
    address,
    contactName,
    description,
    mail,
    title,
    tel,
    subtitle,
    footerNavigation,
  } = footerItems;

  const telFr = 0 + tel.slice(3);

  const goodSlug = (item) => {
    if (item._type === 'page') {
      if (item.slug.current === 'home' || item.slug.current === undefined) {
        return '/';
      }
      return `/${item.slug.current}`;
    }
    return '/faq';
  };

  return (
    <FooterStyles>
      <div className="container">
        <Bird className="bird" />
        <p className="title">
          {title}
          <br />
          {subtitle}
          <span> {description}</span>
        </p>
        {(facebook || linkedin || instagram || twitter) && (
          <>
            <div className="circle" />
            <div className="footer__networks">
              <span className="label">Suivez-nous</span>
              <ul className="iconsMedias">
                {facebook && (
                  <li>
                    <a href={facebook} target="_blank" rel="noreferrer">
                      <Facebook />
                    </a>
                  </li>
                )}
                {linkedin && (
                  <li>
                    <a href={linkedin} target="_blank" rel="noreferrer">
                      <LinkedIn />
                    </a>
                  </li>
                )}
                {instagram && (
                  <li>
                    <a href={instagram} target="_blank" rel="noreferrer">
                      <Instagram />
                    </a>
                  </li>
                )}
                {twitter && (
                  <li>
                    <a href={twitter} target="_blank" rel="noreferrer">
                      <Twitter />
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </>
        )}
        <div className="circle" />
        <div className="contact">
          <span className="contact--title">Contact</span>
          <p className="contact--text">
            {contactName}
            {' • '}
            {tel && (
              <a href={`tel:${tel}`} rel="noreferrer">
                {numberWithSpaces(telFr)}
              </a>
            )}
            {' • '}
            {mail && (
              <a href={`mailto:${mail}`} rel="noreferrer">
                {mail}
              </a>
            )}
            <br />
            {address}
          </p>
        </div>
        {footerNavigation.length > 0 && (
          <nav>
            <ul>
              {footerNavigation.map((item) => (
                <li key={item.id}>
                  <Link to={goodSlug(item)}>
                    {item.titleMenu ? (
                      <span className="text">{item.titleMenu}</span>
                    ) : (
                      <span className="text">{item.title}</span>
                    )}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        )}
      </div>
    </FooterStyles>
  );
};

export default Footer;
