import { createGlobalStyle } from 'styled-components';
import { mq } from './breakpoints';
import { calcClamp, lsEm } from './Mixins';

const Typography = createGlobalStyle`

  h1,h2,h3,h4,h5,h6 {
    font-family: var(--font-titles);
    letter-spacing: ${lsEm(50)};
    line-height: calc(42 / 40);
    margin: 0;
  }

  .center {
    text-align: center;
  }

  /* Custom */
  .site-title {
    font-size: 1.5rem;
    font-weight: 500;

    ${mq[1]} {
      font-size: clamp(2rem, 5vh, 2.5rem);
    }
  }
  .section-title {
    font-size: 1.8rem;
    font-weight: 300;

    ${mq[0]} {
      font-size: 2.5rem;
    }
  }
  .title-middle {
    font-size: 1.5rem;
    font-weight: 300;
  }
  .title-small {
    font-size: 1.25rem;
    font-weight: 300;
  }
  .title-xs {
    font-size: 1rem;
    font-weight: 300;
  }

  .align-left {
    display: block;
    text-align: left;
  }
  .align-center {
    display: block;
    text-align: center;
  }
  .align-right {
    display: block;
    text-align: right;
  }
  .align-justify {
    display: block;
    text-align: justify;
  }

`;

export default Typography;
