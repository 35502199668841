import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import gsap from 'gsap';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { globalHistory } from '@reach/router';
import { mq } from '../styles/breakpoints';

const BurgerStyles = styled.button`
  background: none;
  border: none;
  box-shadow: none;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  padding: 0;
  pointer-events: auto;
  top: 1.5625rem;
  transition: color 0.15s linear;
  z-index: 5;

  svg {
    display: block;
    fill: none;
    position: relative;
    stroke: var(--blue-dark);
    stroke-width: 1px;
    width: 2.3125rem;
    z-index: 3;
  }

  ${mq[2]} {
    display: none;
  }
`;

export default function Burger({ open, setOpen }) {
  const [menuToggle] = useState(
    gsap.timeline({ paused: true, reversed: true })
  );

  const burgerRef = useRef();

  // Toggle menu
  useEffect(() => {
    const top = burgerRef.current.querySelector('.top');
    const mid = burgerRef.current.querySelector('.mid');
    const bot = burgerRef.current.querySelector('.bot');

    menuToggle
      // .set(navRef.current, { stroke: '#ffffff' })
      .to(top, { duration: 0.2, y: '-7px', transformOrigin: '50% 50%' }, 'burg')
      .to(bot, { duration: 0.2, y: '7px', transformOrigin: '50% 50%' }, 'burg')
      .to(mid, { duration: 0.2, scale: 0, transformOrigin: '50% 50%' }, 'burg')
      .add('rotate')
      .to(top, { duration: 0.2, y: 12.5 }, 'rotate')
      .to(bot, { duration: 0.2, y: -12.5 }, 'rotate')
      .to(
        top,
        { duration: 0.2, rotationZ: 45, transformOrigin: '50% 50%' },
        'rotate'
      )
      .to(
        bot,
        { duration: 0.2, rotationZ: -45, transformOrigin: '50% 50%' },
        'rotate'
      );
  }, [menuToggle]);

  useEffect(() => {
    if (open) {
      menuToggle.restart();
    } else {
      menuToggle.reverse();
    }
  }, [open, menuToggle]);

  useEffect(
    () =>
      globalHistory.listen(({ action }) => {
        if (action === 'PUSH') {
          menuToggle.reverse();
          setOpen(false);
        }
      }),
    [menuToggle, setOpen]
  );

  return (
    <BurgerStyles type="button">
      <Link
        to="#"
        ref={burgerRef}
        open={open}
        onClick={(e) => {
          e.preventDefault();
          setOpen(!open);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 44.3 44.4"
          width="44"
          height="44"
        >
          <path className="top" d="M0 9.7h44.3" />
          <path className="mid" d="M0 21.7h44.3" />
          <path className="bot" d="M0 34.4h44.3" />
        </svg>
      </Link>
    </BurgerStyles>
  );
}

Burger.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
