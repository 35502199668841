/* eslint-disable react/display-name */
import { Link } from 'gatsby';
import React, { forwardRef } from 'react';

import styled from 'styled-components';
import { mq } from '../styles/breakpoints';
import InlineSvg from './InlineSVG';

const SecondNavStyles = styled.nav`
  border-top: 1.5px solid var(--blue-dark);
  border-bottom: 1.5px solid var(--blue-dark);
  display: none;

  ul {
    display: flex;
  }

  li {
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 6.25rem;
    margin: 0.9375rem auto;
  }

  .icon-bg {
    background: var(--blue-dark);
    /* border: 1px solid var(--white); */
    border-radius: 100%;
    box-shadow: 1px 1px 4px 0px rgb(0, 0, 0, 0.2);
    height: 2.8125rem;
    margin: auto;
    padding: 0.5563rem 0.7625rem 0.8375rem 0.7438rem;
    width: 2.8125rem;
  }

  a {
    color: var(--blue-dark);
    font-size: 0.75rem;
    text-align: center;
    text-transform: uppercase;

    &:hover,
    &:active,
    &:focus,
    &[aria-current='page'] {
      .icon-bg {
        background: var(--rose);
      }

      .text {
        color: var(--rose);
      }
    }

    .text {
      display: none;
    }
  }

  ${mq[2]} {
    --left: calc((100% - 73.75rem) / 2);
    display: block;
    left: max(var(--left), 2rem);
    max-width: 4rem;
    position: fixed;
    top: calc(7.3125rem + var(--margin));
    z-index: 10;

    .home & {
      visibility: hidden;
      pointer-events: none;
    }

    ul {
      display: block;
    }

    li {
      margin: 2.1875rem auto;
    }

    .text {
      margin-top: 0.8125rem;
      display: block !important;
    }
  }
`;

const Nav = forwardRef((props, ref) => {
  const { secondNav } = props;
  return (
    <SecondNavStyles ref={ref}>
      <ul>
        {secondNav.map((item, index) => (
          <li key={`tab-${index}`}>
            <Link
              to={`/${item.linkPage.slug.current}/`}
              activeClassName="active"
            >
              <div className="icon-bg">
                <InlineSvg src={item.icon} />
              </div>
              {item.title && <span className="text">{item.title}</span>}
            </Link>
          </li>
        ))}
      </ul>
    </SecondNavStyles>
  );
});

export default Nav;
